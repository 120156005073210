// Libs
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { NotionRenderer } from 'react-notion-x';
import { Code } from 'react-notion-x/build/third-party/code'
import { Collection } from 'react-notion-x/build/third-party/collection'
import { Equation } from 'react-notion-x/build/third-party/equation'
import { Modal } from 'react-notion-x/build/third-party/modal'
import { Pdf } from 'react-notion-x/build/third-party/pdf'

// Stylesheet
import './NotionEmbed.scss';
import 'react-notion-x/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'katex/dist/katex.min.css';

// Atoms
import { selectedColorTheme} from "../../atoms/colorTheme";

// Utils
import axios from '../../utils/axios';

export default function NotionEmbed(props) {
    const { t, i18n } = useTranslation();
    const { notionPageId } = props;
    let colorTheme = useRecoilValue(selectedColorTheme);
    console.log(colorTheme);
    const [notionContent, setNotionContent] = useState(null); // Utilisation de null au lieu de {}

    useEffect(() => {
        axios.post('api/notion', { notionPageId })
            .then(res => {
                setNotionContent(res.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, [notionPageId]);

    useEffect(() => {
        console.log(notionContent);
    }, [notionContent]);

    if (notionContent === null) {
        return <div>Loading...</div>; // Affiche un message de chargement tant que notionContent est null
    }

    return (
        <div className="notion-embed">
            <NotionRenderer
                recordMap={notionContent}
                fullPage={true}
                darkMode={colorTheme === 'dark' ? true : false}
                components={{
                    Code,
                    Collection,
                    Equation,
                    Modal,
                    Pdf
                }}
            />
        </div>
    );
}