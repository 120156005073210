// Libs
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'

// Atoms
import { visitingTime } from './atoms/basic'

// Helpers
import { history } from './helpers/history'

// Pages
import Index from './pages/Index/Index'

export default function Routing() {
	// Matomo
	useEffect(() => {
			var _mtm = window._mtm = window._mtm || [];
			_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
			(function() {
			var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
			g.async=true; g.src='https://matomo.devausorus.com/js/container_L7llSXl1.js'; s.parentNode.insertBefore(g,s);
		})();
	})
	// End Matomo
	return (
		<Router history={history}>
			<Routes>
				{/* Homepage */}
				<Route path='/' element={<Index />} />
			</Routes>
		</Router>
	)
}