import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
        "navbar.FullName": "Damien Foulon",
        "navbar.Home": "Home",
        "navbar.About": "About",
        "navbar.Work": "Work",
        "navbar.Lang": "Change language",
        "navbar.ColorTheme": "Change theme",
        "navbar.Contact": "Contact me ✉️",
        "hero.Title": "Hello, I’m Damien Foulon",
        "hero.Description": "I am a 20-year-old aspiring web developer with a strong foundation in frontend development using React and backend development using Express on Node.js. Currently employed at Babaweb, I am passionate about honing my skills in crafting dynamic and user-friendly web interfaces. My goal is to leverage technology to create seamless and visually engaging designs that address real-world challenges.",
        "resume.Download": "Download my resume",
        "experiences.MainTitle": "My",
        "experiences.TitleSpan": "experiences",
        "experiences.babaweb.Title": "Full stack developer at",
        "experiences.babaweb.Date": "October 2021 - October 2023",
        "experiences.babaweb.Task1": "Realization of tailor-made Wordpress themes based on models.",
        "experiences.babaweb.Task2": "Integration of templates in HTML/CSS/JS/PHP.",
        "experiences.babaweb.Task3": "Implementation of showcase and e-commerce sites as well as their dedicated backoffice.",
        "experiences.3w-academy.Title": "Full stack developer at",
        "experiences.3w-academy.Date": "August 2021 - August 2023",
        "experiences.3w-academy.Task1": "Learned Full Stack Web Development based on React / Express / MongoDB.",
        "experiences.3w-academy.Task2": "Learned database management with MongoDB and data manipulation with SQL.",
        "experiences.3w-academy.Task3": "Learned the AGILE and SCRUM method.",
        "works.Title": "My latest ",
        "works.TitleSpan": "work",
        "works.paresport.Desc": "Paresport is a web application that allows you to place bets on esport matches.",
        "works.devausor-us.Desc": "Devausor.us is a web application that allows you to create short links.",
        "works.association-saed.Desc": "A site for an animal rescue association that I offered as a donation.",
        "certificates.MainTitle": "My",
        "certificates.TitleSpan": "certificates",
        "certificates1.Title": "Full stack web developer",
        "certificates2.Title": "Discover algorithms operations",
        "certificates3.Title": "Create your website with HTML5 and CSS3",
        "certificates4.Title": "Write JavaScript for the web",
        "courses.MainTitle": "My courses",
        "courses.TitleSpan": "notes",
        "courses1.Title": "AI introduction",
        "courses2.Title": "Laravel",
        "banner.Text": "Ready to start your next project ?",
        "banner.CTA": "Get it touch me !",
        "footer.MadeBy": "Damien Foulon",
        "modal.Subject": "Subject",
        "modal.Subject.Default": "Select a subject",
        "modal.Subject.WebDevelopment": "Web development",
        "modal.Subject.Hiring": "Hiring",
        "modal.Firstname": "First name",
        "modal.Firstname.Placeholder": "John",
        "modal.Lastname": "Last name",
        "modal.Lastname.Placeholder": "Doe",
        "modal.Email": "Email",
        "modal.Email.Placeholder": "john.doe@myworkspace.fr",
        "modal.PhoneNumber": "Phone number",
        "modal.PhoneNumber.Placeholder": "06 12 34 56 78",
        "modal.Message": "Message",
        "modal.Message.Placeholder": "Type your message here... \n 2000 characters maximum",
        "modal.Submit": "Submit",
    },
  },
  fr: {
    translation: {
        "navbar.FullName": "Damien Foulon",
        "navbar.Home": "Accueil",
        "navbar.About": "À propos",
        "navbar.Work": "Réalisations",
        "navbar.Lang": "Changer de langue",
        "navbar.ColorTheme": "Changer de thème",
        "navbar.Contact": "Contactez-moi ✉️",
        "hero.Title": "Je suis, Damien Foulon",
        "hero.Description": "Un développeur web de 20 ans avec une solide expérience en développement frontend utilisant React et en développement backend utilisant Express sur Node.js. Actuellement employé chez Babaweb, je suis passionné par le perfectionnement de mes compétences dans la création d'interfaces web dynamiques et conviviales. Mon objectif est d'utiliser la technologie pour créer des designs fluides et visuellement attrayants qui répondent aux défis du monde réel.",
        "resume.Download": "Télécharger mon CV",
        "experiences.MainTitle": "Mes",
        "experiences.TitleSpan": "expériences",
        "experiences.babaweb.Title": "Full stack développeur chez",
        "experiences.babaweb.Date": "Octobre 2021 - Octobre 2022",
        "experiences.babaweb.Task1": "Réalisation de thèmes Wordpress sur-mesure basés sur des maquettes.",
        "experiences.babaweb.Task2": "Intégration de maquettes en HTML/CSS/JS/PHP.",
        "experiences.babaweb.Task3": "Mise en place de sites vitrines et e-commerce ainsi que leurs backoffice dédiés.",
        "experiences.3w-academy.Title": "Formation de développeur web full-stack chez",
        "experiences.3w-academy.Date": "Août 2021 - Août 2023",
        "experiences.3w-academy.Task1": "Apprentissage du développement web full-stack sur React / Express / MongoDB.",
        "experiences.3w-academy.Task2": "Apprentissage de la gestion d'une base de donnée avec MongoDB et manipulation de donnée avec SQL.",
        "experiences.3w-academy.Task3": "Apprentissage de la gestion de projet avec la méthode Agile et SCRUM.",
        "works.Title": "Mes dernières ",
        "works.TitleSpan": "réalisations",
        "works.paresport.Desc": "Paresport est une application web qui permet de placer des paris sur des matchs d'esport.",
        "works.devausor-us.Desc": "Devausor.us est une application web qui permet de créer des liens raccourcis.",
        "works.association-saed.Desc": "Le site de l'association SAED que j'ai réalisé en tant que donation.",
        "certificates.MainTitle": "Mes",
        "certificates.TitleSpan": "certificats",
        "certificates1.Title": "Développeur web full stack",
        "certificates2.Title": "Découvrez le fonctionnement des algorithmes",
        "certificates3.Title": "Créez votre site web avec HTML5 et CSS3",
        "certificates4.Title": "Écrivez du JavaScript pour le web",
        "banner.Text": "Prêt à démarrer un nouveau projet ?",
        "courses.MainTitle": "Mes notes de",
        "courses.TitleSpan": "cours",
        "courses1.Title": "Initiation à l'IA",
        "courses2.Title": "Laravel",
        "banner.CTA": "Contactez-moi !",
        "footer.MadeBy": "Damien Foulon",
        "modal.Subject": "Sujet",
        "modal.Subject.Default": "Sélectionnez un sujet",
        "modal.Subject.WebDevelopment": "Développement web",
        "modal.Subject.Hiring": "Recrutement",
        "modal.Firstname": "Prénom",
        "modal.Firstname.Placeholder": "John",
        "modal.Lastname": "Nom",
        "modal.Lastname.Placeholder": "Doe",
        "modal.Email": "Email",
        "modal.Email.Placeholder": "john.doe@myworkspace.fr",
        "modal.PhoneNumber": "Numéro de téléphone",
        "modal.PhoneNumber.Placeholder": "+33 6 12 34 56 78",
        "modal.Message": "Message",
        "modal.Message.Placeholder": "Tapez votre message ici... \n 2000 charactères maximum",
        "modal.Submit": "Envoyer",
    },
  }
};

export default i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});
