// Libs
import { useCallback, useEffect, useMemo, useRef } from "react"

// Style
import './Modal.scss'

export default function Modal(props) {
    const modalRef = useRef(null);
    const { children, ref, className, id, onClose, onCancel, onClick, onAnimEnd } = props;

    return (
        <dialog
            ref={modalRef}
            className={className}
            id={id}
        >
            <div className="modal-container">{children}</div>
        </dialog>
    );
}