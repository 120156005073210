// Libs
import React from 'react'
import { useTranslation } from 'react-i18next'
import {useRecoilValue} from "recoil";

// Styles
import './Hero.scss'
import {selectedColorTheme} from "../../atoms/colorTheme";

export function Hero(props) {
  const { t } = useTranslation();
  const theme = useRecoilValue(selectedColorTheme);
  return (
    <section className='hero' id='section-hero'>
        <div className='hero-container'>
            <h1 className='hero-title'>
                {t(props.title)}
            </h1>
            <p className='hero-description formatted'>
                {t(props.description)}
            </p>
            <ul className='hero-skills-list'>
                {props.skills.map((skill, index) => {
                    return <li key={index} className='hero-skills-list-item'>
                        <i className={`hero-skills-list-item-text icon-${skill.icon}`}></i>
                        <span className='hero-skills-list-item-text'>{skill.name}</span>
                    </li>
                })}
            </ul>
            <a href={`/assets/pdf/resume-${theme}-theme.pdf`} download={`foulon-damien-resume-${theme}.pdf`} title={t('resume.Download')} className="download-resume primary-button">{t('resume.Download')}</a>
        </div>
    </section>
  )
}

export default Hero