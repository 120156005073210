// Libs
import React from "react";

// Stylesheet
import "./Popup.scss";

// Components
import NotionEmbed from "../NotionEmbed/NotionEmbed";

export default function Popup(props) {
  const { children, className, course, onClose } = props;
  const handleClick = (e) => {
    e.currentTarget === e.target && onClose();
  }

  return (
      <div className={className ? `popup-overlay ${className}` : 'popup-overlay'} onClick={handleClick}>
        <div className="popup-content">
          <NotionEmbed notionPageId={course.notionPageId} fullPage={true} />
          {children}
        </div>
      </div>
  )
}