// Libs
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import './lang'

// Stylesheet
import './assets/styles/main.scss'

// Router
import Routing from './routes'


const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<RecoilRoot>
		<Routing />
	</RecoilRoot>
)