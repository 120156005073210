import './Courses.scss'
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import NotionEmbed from "../NotionEmbed/NotionEmbed";

export default function Courses(props) {
  const { t, i18n } = useTranslation();
  const { courses, onCourseClick } = props;

    const [hoveredCertificateIndex, setHoveredCertificateIndex] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        let rect = e.currentTarget.getBoundingClientRect();
        setMousePosition({ x: e.clientX - rect.left, y:e.clientY - rect.top });
    };

    const handleMouseEnter = (index) => {
        setHoveredCertificateIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredCertificateIndex(null);
    };

  return (
    <>
      <section id="section-courses">
        <h2 className="courses-title">
          {t('courses.MainTitle')}{' '}
            <span className="color-tertiary">{t('courses.TitleSpan')}</span>
        </h2>
        <div className="courses-container">
            {courses.map((course, index) => {
                return (
                    <div
                        className="course"
                        key={index}
                        onClick={() => onCourseClick(course)}
                        onMouseMove={handleMouseMove}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <h3 className="course-title">{course.title}</h3>
                        <img src={course.preview} alt=""/>

                        <div
                            className={`cursor-download ${hoveredCertificateIndex === index ? 'visible' : ''}`}
                            style={{
                                left: (mousePosition.x + 15) + 'px',
                                top:  (mousePosition.y + 15) + 'px',
                            }}
                        >
                            <svg className="read-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path className="fa-primary" d="M330.7 376L457.4 502.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L376 330.7C363.3 348 348 363.3 330.7 376z"/>
                                <path class="fa-secondary" d="M208 64a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 352A208 208 0 1 0 208 0a208 208 0 1 0 0 416z"/>
                            </svg>
                        </div>
                    </div>
                )
            })}
        </div>
      </section>
    </>
  )
}