// Libs
import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

// Utils
import axios from '../../utils/axios'

// Atoms
import { modalState } from '../../atoms/modal'

// Stylesheet
import './Index.scss'

// Components
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/Hero/Hero'
import Experience from "../../components/Experience/Experience"
import Work from '../../components/Work/Work'
import Certificates from "../../components/Certificates/Certificates";
import Banner from "../../components/Banner/Banner"
import Footer from '../../components/Footer/Footer'
import Modal from '../../components/Modal/Modal'
import Courses from "../../components/Courses/Courses";
import Popup from "../../components/Popup/Popup";

export default function Index() {
	const {t} = useTranslation();
	const setModalState = useSetRecoilState(modalState);
	const [selectedCourse, setSelectedCourse] = useState(null);
	const [courses, setCourses] = useState([]);
	const [work, setWork] = useState([]);
	const [experience, setExperience] = useState([]);
	const [certificates, setCertificates] = useState([]);
	const hero = {
		title: t('hero.Title'),
		description: t('hero.Description'),
		skills: [
			{
				name: 'HTML',
				icon: 'html5'
			},
			{
				name: 'CSS',
				icon: 'css3'
			},
			{
				name: 'JavaScript',
				icon: 'javascript'
			},
			{
				name: 'React',
				icon: 'react'
			},
			{
				name: 'Node.js',
				icon: 'node-js'
			},
			{
				name: 'Express',
				icon: 'express'
			},
			{
				name: 'MongoDB',
				icon: 'mongodb'
			},
			{
				name: 'Github',
				icon: 'github'
			}
		]
	}
	const banner = {
		text: 'Ready to start your next project ?',
		cta: {
			text: 'Get it touch me !',
			link: 'mailto:damien-foulon@myworkspace.fr'
		}
	}

	useEffect(() => {
		axios.get('api/getCourses').then((res) => {
			setCourses(res.data)
		})
		axios.get('api/getWorks').then((res) => {
			setWork(res.data)
		})
		axios.get('api/getExperiences').then((res) => {
			setExperience(res.data)
		})
		axios.get('api/getCertificates').then((res) => {
			setCertificates(res.data)
		})
	}, [])

	useEffect(() => {
		const modalContainer = document.querySelector('#contact-modal .modal-container')
		modalContainer.addEventListener('click', (e) => {
			if (e.target === modalContainer && e.currentTarget === modalContainer) {
				closeModal()
			}
		})
	})

	const closeModal = () => {
		const modal = document.querySelector('#contact-modal')
		modal.close();
		setModalState(false);
	}

	const submitForm = (e) => {
		e.preventDefault()
		const contactForm = document.querySelector('#contact-form')
		const formData = new FormData(contactForm)
		const data = Object.fromEntries(formData)
		if (data.firstname && data.lastname && data.email && data.subject && data.message) {
			axios.post('api/contact', data).then((res) => {
				if (res.status === 200) {
					contactForm.reset()
					contactForm.classList.remove('error')
					contactForm.classList.add('success')
					contactForm.innerHTML = `
							<div class="form-success">
								<h2 class="form-success-title">Your message has been sent successfully !</h2>
								<p class="form-success-description">I will get back to you as soon as possible.</p>
							</div>
						`
				}
			})
		} else {
			contactForm.classList.remove('success')
			contactForm.classList.add('error')
			contactForm.innerHTML = `
				<div class="form-error">
					<h2 class="form-error-title">An error has occurred !</h2>
					<p class="form-error-description">Please fill in all the fields correctly.</p>
				</div>
			`
		}
	}

	const handleCourseClick = (course) => {
		setSelectedCourse(course)
	}

	const closePopup = () => {
		setSelectedCourse(null)
	}

	return (
		<>
			{selectedCourse ? (
				<Popup course={selectedCourse} onClose={closePopup}/>
			) : null
			}
			<Navbar/>
			<Hero
				title={hero.title}
				description={hero.description}
				skills={hero.skills}
			/>
			<Experience
				experience={experience}
			/>
			<Certificates
				certificates={certificates}
			/>
			<Work
				works={work}
			/>
			<Courses
				courses={courses}
				onCourseClick={handleCourseClick}
			/>
			<section id="section-banner">
				<Banner
					banner={banner}
				/>
			</section>
			<Footer/>
			<Modal className="modal" id="contact-modal" onClose={closeModal}>
				<div className="modal-content">
					<a className="modal-closer" onClick={closeModal}>
						✖️
					</a>
					<form id="contact-form">
						<div className="input-row">
							<label className="input-label">
								<span className="input-label-text required">{t('modal.Subject')}</span>
								<select name="subject" className="input" required>
									<option value="default">{t('modal.Subject.Default')}</option>
									<option value="web-development">{t('modal.Subject.WebDevelopment')}</option>
									<option value="hiring">{t('modal.Subject.Hiring')}</option>
								</select>
							</label>
						</div>
						<div className="input-row">
							<label className="input-label">
								<span className="input-label-text required">{t("modal.Firstname")}</span>
								<input className="input" type="text" name="firstname"
									   placeholder={t("modal.Firstname.Placeholder")} required/>
							</label>

							<label className="input-label">
								<span className="input-label-text required">{t("modal.Lastname")}</span>
								<input className="input" type="test" name="lastname"
									   placeholder={t("modal.Lastname.Placeholder")} required/>
							</label>
						</div>
						<div className="input-row">
							<label className="input-label">
								<span className="input-label-text required">{t("modal.Email")}</span>
								<input className="input" type="email" name="email"
									   placeholder={t("modal.Email.Placeholder")} required/>
							</label>

							<label className="input-label">
								<span className="input-label-text">{t("modal.PhoneNumber")}</span>
								<input className="input"
									   type="tel"
									   name="phone"
									   pattern="^(\+?[0-9]+|0[0-9](?:[\s-]?[0-9]{2}){4}|[0-9]{4}-[0-9]{4})$"
									   placeholder={t("modal.PhoneNumber.Placeholder")}
								/>
							</label>
						</div>
						<div className="input-row">
							<label className="input-label">
								<span className="input-label-text required">{t("modal.Message")}</span>
								<textarea className="input" name="message"
										  placeholder={t("modal.Message.Placeholder")} maxLength={2000} required/>
							</label>
						</div>
						<div className="input-row">
							<button className="button primary-button submit-form-button"
									onClick={submitForm}>{t("modal.Submit")}</button>
						</div>
					</form>
				</div>
			</Modal>
		</>
	)
}