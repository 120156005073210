// Libs
import React from 'react'
import { useTranslation } from 'react-i18next'

// Style
import './Footer.scss'

export default function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <footer>
      <div className="footer-left">
        <p>© 2023 - {new Date().getFullYear()} {t('footer.MadeBy')} </p>
      </div>
      <div className="footer-right">
          <ul className="social-list">
            <li className="social-list-item">
                <a href="https://www.linkedin.com/in/damien-foulon" title="linkedIn" target="_blank" rel="noopener noreferrer">
                    <i className="icon-linkedin"></i>
                </a>
            </li>
            <li className="social-list-item">
                <a href="https://github.com/DamienFoulon" title="github" target="_blank" rel="noopener noreferrer">
                    <i className="icon-github"></i>
                </a>
            </li>
          </ul>
      </div>
    </footer>
  )
}