import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

// Stylesheet
import './Certificates.scss';

export default function Certificates(props) {
    const { t } = useTranslation();
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        draggable: true,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                },
            },
        ],
    };
    const certificates = props.certificates;

    const [hoveredCertificateIndex, setHoveredCertificateIndex] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        let rect = e.target.getBoundingClientRect();
        setMousePosition({ x: e.clientX - rect.left, y:e.clientY - rect.top });
    };

    const handleMouseEnter = (index) => {
        setHoveredCertificateIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredCertificateIndex(null);
    };

    return (
        <>
            <section id="section-certificates">
                <h2 className="certificates-title">
                    {t('certificates.MainTitle')}{' '}
                    <span className="color-tertiary">{t('certificates.TitleSpan')}</span>
                </h2>
                <div className="certificates-container">
                    <Slider {...sliderSettings}>
                        {certificates.map((certificate, index) => (
                            <div className="slider-item certificate-item" key={index}>
                                <div className="certificate-item-content">
                                    <div className="certificate-item-content-title">
                                        <h3>{certificate.title}</h3>
                                    </div>
                                    <div
                                        className="certificate-item-content-logo"
                                        onMouseMove={handleMouseMove}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => window.open(certificate.link, '_blank')}
                                    >
                                        <div
                                            className={`cursor-download ${hoveredCertificateIndex === index ? 'visible' : ''}`}
                                            style={{
                                                left: (mousePosition.x + 15) + 'px',
                                                top:  (mousePosition.y + 15) + 'px',
                                            }}
                                        >
                                            <svg className="download-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path className="fa-primary" d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 242.7V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V242.7L86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"/>
                                                <path className="fa-secondary" d="M0 448c0 17.7 14.3 32 32 32H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"/>
                                            </svg>
                                        </div>
                                        <img src={certificate.logo} alt={certificate.title} />
                                    </div>
                                    <div className="certificate-item-content-credits">
                                        <div className="certificate-item-content-date">
                                            <span>{certificate.date}</span>
                                        </div>
                                        <div className="separator"></div>
                                        <div className="certificate-item-content-provider">
                                            <span>{certificate.provider}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    );
}
