// Libs
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// Style
import './Experience.scss'

export default function Experience(props) {
  const { t, i18n } = useTranslation();
  const [ selectedExperience, setSelectedExperience ] = useState(null);
  const experience = props.experience;
  function handleClick(index) {
      setSelectedExperience(index);
  }

    useEffect(() => {
        if(experience.length > 0) {
            setSelectedExperience(0);
        }
    }, [experience]);

  return (
      <section className="experience" id="section-experience">
          <h2>{t('experiences.MainTitle')} <span className="color-tertiary">{t('experiences.TitleSpan')}</span></h2>
          <div className="experience-container">
              <div className="experience-content">
                  <ul className="experience-list">
                      {props.experience.map((experience, index) => {
                          const isActive = index === selectedExperience;
                          const itemClasses = isActive ? 'experience-list-item active' : 'experience-list-item';
                          const slug = experience.slug;
                          return (
                              <li
                                  key={index}
                                  className={itemClasses}
                                  onClick={() => handleClick(index)}
                              >
                                  <div className="experience-list-item-logo">
                                      <img src={experience.logo} alt={experience.title} />
                                  </div>
                                  <div className="experience-list-item-content">
                                      <p className="experience-list-item-title">{experience.title}</p>
                                      <span className="experience-list-item-date light-text">{t(`experiences.${slug}.Date`)}</span>
                                  </div>
                              </li>
                          );
                      })}
                  </ul>
                  {selectedExperience !== null ? (
                      <div className="experience-detail">
                          <div className="experience-detail-header">
                              <p className="experience-detail-title">
                                  {t(`experiences.${experience[selectedExperience].slug}.Title`)} <a className={"underline-anim color-tertiary"} href={experience[selectedExperience].details.company.link} title={experience[selectedExperience].details.company.name} target={"_blank"}>@{experience[selectedExperience].details.company.name}</a>
                              </p>
                              <span className="experience-detail-date light-text">{t(`experiences.${experience[selectedExperience].slug}.Date`)}</span>
                          </div>
                          <div className="experience-detail-content">
                              <ul className="experience-detail-content-list">
                                  {experience[selectedExperience].details.tasks.map((task, index) => {
                                      return <li key={index} className="experience-detail-content-list-item">
                                          <i className="icon-angle-right color-tertiary"></i>
                                          <span>{t(`experiences.${experience[selectedExperience].slug}.Task${index+1}`)}</span>
                                      </li>
                                  })}
                              </ul>
                          </div>
                      </div>
                  ) : null }
              </div>
          </div>
      </section>
  )
}