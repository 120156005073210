// Libs
import './Navbar.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState, useRecoilValue } from 'recoil';

// Hooks
import useColorTheme from '../../hooks/useColorTheme';

// Atoms
import { lang } from '../../atoms/lang';
import { modalState } from '../../atoms/modal';

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const { colorTheme, toggleColorMode } = useColorTheme();
  const [flag, setFlag] = useState('us-flag');
  const setSelectedLang = useSetRecoilState(lang);
  const selectedLang = useRecoilValue(lang);
  const setModalState = useSetRecoilState(modalState);
  const isModalOpen = useRecoilValue(modalState);
  const contactModal = document.querySelector('#contact-modal');

  useEffect(() => {
    switch (selectedLang) {
      case 'en-US':
        setFlag('french-flag');
        break;
      case 'fr-FR':
        setFlag('us-flag');
        break;
      default:
        setFlag('us-flag');
        break;
    }
  }, [selectedLang]);

  const changeLang = () => {
    switch (selectedLang) {
      case 'en-US':
        setSelectedLang('fr-FR');
        i18n.changeLanguage('fr-FR');
        break;
      case 'fr-FR':
        setSelectedLang('en-US');
        i18n.changeLanguage('en-US');
        break;
      default:
        setSelectedLang('en-US');
        i18n.changeLanguage('en-US');
        break;
    }
  }

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        setModalState(false);
      }
    };

    if (isModalOpen) {
      document.body.style.overflowY = 'hidden';
      window.addEventListener('keydown', handleEscapeKeyPress);
    } else {
      document.body.style.overflowY = 'scroll';
      window.removeEventListener('keydown', handleEscapeKeyPress);
    }

    return () => {
      document.body.style.overflowY = 'scroll';
      window.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [isModalOpen, setModalState]);

  const showModal = async () => {
    setModalState(true);
    contactModal.showModal();
  }

  return (
    <header>
      <nav>
        <div className="nav-left">
            <a href="/">{t('navbar.FullName')}</a>
        </div>
        <div className="nav-right">
          <ul className="text-nav">
            <li className="redirect-text">
              <a className="underline-anim" href="#section-home" title={t('navbar.Home')}>{t('navbar.Home')}</a>
            </li>
            <li className="redirect-text">
              <a className="underline-anim" href="#section-hero" title={t('navbar.About')}>{t('navbar.About')}</a>
            </li>
            <li className="redirect-text">
              <a className="underline-anim" href="#section-experience" title={t('navbar.Work')}>{t('navbar.Work')}</a>
            </li>
          </ul>
          <ul className="icons-nav">
            <li className="redirect-icon">
              <a href="https://github.com/DamienFoulon/" title={'Github'}>
                <i className="icon-github"></i>
              </a>
            </li>
            <li className="icon-button">
              <button className='icon-button' onClick={changeLang} title={t('navbar.Lang')}>
                <img className='button-image' src={`/assets/img/svg/${flag}.svg`} alt={selectedLang}/>
              </button>
            </li>
            <li className="icon-button">
              <button className='icon-button' onClick={toggleColorMode} title={t('navbar.ColorTheme')}>
                {colorTheme ? colorTheme === 'light' ? (
                  <i className='icon-moon-stars'></i>
                ) : (
                  <i className='icon-brightness'></i>
                ): null}
              </button>
            </li>
          </ul>
          <ul className="button-nav">
            <li className="nav-right">
                <button className='primary-button cta-contact'
                        onClick={showModal}>
                    {t('navbar.Contact')}
                </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}