// Libs
import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next'

// Styles
import './Banner.scss'
import {useRecoilValue, useSetRecoilState} from "recoil";
import {modalState} from "../../atoms/modal";

export default function Banner(props) {
  const { t, i18n } = useTranslation();
  let { text, cta } = props.banner
  const setModalState = useSetRecoilState(modalState);
  const isModalOpen = useRecoilValue(modalState);
  const contactModal = document.querySelector('#contact-modal');

  useEffect(() => {
        const handleEscapeKeyPress = (event) => {
            if (event.key === 'Escape') {
                setModalState(false);
            }
        };

        if (isModalOpen) {
            document.body.style.overflowY = 'hidden';
            window.addEventListener('keydown', handleEscapeKeyPress);
        } else {
            document.body.style.overflowY = 'scroll';
            window.removeEventListener('keydown', handleEscapeKeyPress);
        }

        return () => {
            document.body.style.overflowY = 'scroll';
            window.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [isModalOpen, setModalState]);

  const showModal = async () => {
        setModalState(true);
        contactModal.showModal();
    }
  return (
      <div className="banner">
          <p className="banner-text">
                {t("banner.Text")}
              <br/>
                <a className="underline-anim color-tertiary cta-contact" onClick={showModal}>{t('banner.CTA')}</a>
          </p>
      </div>
  )
}