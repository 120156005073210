// Libs
import React from 'react'
import { useTranslation } from 'react-i18next'

// Style
import './Work.scss'

export default function Work(props) {
  const { t, i18n } = useTranslation();
  const works = props.works
  return (
    <section className="work" id="section-work">
        <h2 className="work-title">{t('works.Title')} <span className="color-tertiary">{t('works.TitleSpan')}</span></h2>
        <div className="work-list">
            {works.map((work, index) => {
                return (
                    <div className="work-list-item" key={index}>
                        <div className="work-header">
                            <h5 className="work-title">{work.title}</h5>
                            <span className="work-date light-text">{work.date}</span>
                        </div>
                        <div className="work-body">
                            <div className="work-illustration">
                                <img src={work.illustration} alt="Illustration"/>
                            </div>
                            <div className="work-cta">
                                <div className="work-stack">
                                    {work.stack.map((stack, index) => {
                                        return <span key={index} className="work-stack-item">{stack.name}</span>
                                    })}
                                </div>
                                <div className="work-links">
                                    {work.links.github ? (
                                        <a href={work.links.github} title="Github" target="_blank" rel="noopener noreferrer" className="work-links-item">
                                            <i className="work-links-item-icon icon-github"></i>
                                        </a>
                                    ) : null}
                                    {work.links.codesandbox ? (
                                        <a href={work.links.codesandbox} title="Code Sandbox" target="_blank" rel="noopener noreferrer" className="work-links-item">
                                            <i className="work-links-item-icon icon-code-sandbox"></i>
                                        </a>
                                    ) : null}
                                    {work.links.demo ? (
                                        <a href={work.links.demo} title="Demo" target="_blank" rel="noopener noreferrer" className="work-links-item">
                                            <i className="work-links-item-icon icon-arrow-up-right-from-square"></i>
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                            <div className="work-description">
                                <p className="work-description-text">{t(`works.${work.slug}.Desc`)}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </section>
  )
}